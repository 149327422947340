/* eslint-disable no-underscore-dangle */

/**
 * Main application management
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import lod_ from "lodash";
import {
	Badge,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	Menu,
	MenuItem,
	Tooltip
} from "@mui/material";
import { JSONTree } from "react-json-tree";

import axios from "axios";
import i18n from "i18n";
import { useMaterialUIController } from "context";

import ChartsLoader from "components/Custom/ChartsLoader";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";
import MDTypography from "components/Basics/MDTypography";
import OperationActions from "redux-react/actions/operationActions";
import { RestartAlt, StopCircle, PlayCircleFilledWhite } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ButtonNewLabel = ({ handleClick, namePage }) => {
	return (
		<MDButton
			style={{ height: "100%", marginRight: "0.75rem" }}
			variant="contained"
			color="info"
			onClick={handleClick}
			disabled
		>
			<Icon>add</Icon>&nbsp;{i18n.t("FORMS.LABELS.add") + namePage}
		</MDButton>
	);
};

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function MessagesPage({ route }) {
	const dispatch = useDispatch();

	const { filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Raw charts from collection "analytics" in DB
	const [rawCharts, setRawCharts] = useState({});
	// Raw charts builded from the back
	const [chartsData, setChartsData] = useState({});
	// Default assistant filters
	const [pageFilters, setPageFilters] = useState([]);
	const [openPannel, setOpenPannel] = useState(false);
	const [selectedComponent, setSelectedComponent] = useState({});
	const [selectedJSON, setSelectedJSON] = useState({});

	const rightPannelContainer = useRef(null);
	const [reloadTable, setReloadTable] = useState(false);
	const [attributeMyCode, setAttributeMyCode] = useState([]);
	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData() {
		return chartsData[route.route] ?? [];
	}
	/**
	 * FIRST STEP : Get raw charts from DB
	 */
	function getRawChartsFromDatabase() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts({ ...rawCharts, [route.route]: res.charts });
		};
		dispatch(ChartsActions.getCharts(route, onSuccess));
	}
	/**
	 * SECOND STEP : Build charts with the raw charts on the back
	 */
	function buildRawCharts() {
		// Success handler : set charts data
		const onSuccess = res => {
			setChartsData({ ...chartsData, [route.route]: res.charts });
			setChartsLoading(false);
		};
		// Build charts
		if (!rawCharts[route.route]) return;
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		let actualFilters = getLocalStorageBackValues(route.route, filters);
		dispatch(
			ChartsActions.buildRawCharts(
				rawCharts[route.route],
				actualFilters,
				mandatoryFilters,
				onSuccess
			)
		);
	}
	/**
	 * FIRST STEP : Get raw charts :
	 * - On mount / when page change
	 * - On selected assistant change
	 */
	useEffect(() => {
		// if (!chartsData[page]) {
		setChartsLoading(true);
		getRawChartsFromDatabase();
		setOpenPannel(false);
		// }
	}, [route]);
	/**
	 * SECOND STEP : Build charts :
	 * - On filters change
	 * - On rawCharts change
	 */
	useEffect(() => {
		buildRawCharts();
	}, [filters, rawCharts]);
	/**
	 * Load filters on mount
	 */

	const actionViewHandle = (item, collection) => {
		setOpenPannel(true);
		setSelectedComponent(item);
		// if scroll in page is above rightPannelContainer ref, scroll to it
		setTimeout(() => {
			let scrollPosition = document.documentElement.scrollTop;
			let pannelTopPosiiton = rightPannelContainer.current.offsetTop;

			if (scrollPosition < pannelTopPosiiton) {
				rightPannelContainer.current.scrollIntoView({
					behavior: "smooth",
					block: "start"
				});
			}
		}, 200);

		const onSuccess = res => {
			setSelectedJSON(res.message);
		};
		dispatch(OperationActions.getMessageByID({ message: item, collection }, onSuccess));
	};

	const actionCheckHandle = (item, event, target) => {
		let copyAttributeMyCode = lod_.cloneDeep(attributeMyCode);

		if (event.target.checked) {
			copyAttributeMyCode.push({ _id: item._id, target, mID: item["data.header.mID"] });
		} else {
			copyAttributeMyCode = lod_.without(
				copyAttributeMyCode,
				lod_.find(copyAttributeMyCode, obj =>
					lod_.isEqual(obj, { _id: item._id, target, mID: item["data.header.mID"] })
				)
			);
		}
		setAttributeMyCode(copyAttributeMyCode);
	};

	const closePannel = () => {
		setOpenPannel(false);
		setSelectedComponent({});
	};

	const handleMenuClick = event => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("OPERATION.ACTION.replayMessage"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setAttributeMyCode([]);
		};
		dispatch(OperationActions.replayMessages(attributeMyCode, onSuccess));
	};

	const copyToClipboard = () => {
		// Convertir l'objet JSON en une chaîne JSON
		const jsonString = JSON.stringify(selectedJSON, null, 2);

		// Créer un élément de texte temporaire
		const tempTextArea = document.createElement("textarea");
		tempTextArea.value = jsonString;

		// Ajouter l'élément à la page
		document.body.appendChild(tempTextArea);

		// Sélectionner le texte dans l'élément
		tempTextArea.select();

		// Copier le texte dans le presse-papiers
		document.execCommand("copy");

		// Supprimer l'élément temporaire
		document.body.removeChild(tempTextArea);
	};

	/**
	 * Charts loader
	 */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/**
	 * Main component
	 */
		return (
			<DashboardLayout>
				<MDBox mb={3}>
					<DashboardNavbar
						filters={[
							<MDBox display="flex" alignItems="center">
								<ButtonNewLabel
									handleClick={() => {}}
									namePage={route?.form?.pageLabel ?? route.name}
								/>

								{attributeMyCode.length !== 0 && (
									<Badge badgeContent={attributeMyCode.length} color="info">
										<MDButton
											style={{ height: "100%", marginRight: "0.75rem" }}
											variant="contained"
											color="info"
											onClick={handleMenuClick}
										>
											REPLAY ALL
										</MDButton>
									</Badge>
								)}

								<MDBox display="flex">
									{parseFilters(route.route, pageFilters, filters, dispatch)}
								</MDBox>
							</MDBox>
						]}
					/>
				</MDBox>

				{/*
				 * Pagined table
				 */}
				{getChartsData()
					.filter(chart => chart.type === "paginedList")
					.map((chart, index) => {
						return (
							<MDBox
								className="pageContentContainer"
								style={{
									display: openPannel ? "flex" : "block"
								}}
							>
								<MDBox
									flex="1"
									style={{
										width: openPannel ? "auto" : "auto"
									}}
								>
									<DefaultDataTable
										reloadTable={reloadTable}
										dictionary={chart.dictionary}
										list={chart}
										pagination={chart.pagination}
										canSearch
										key={index}
										table={chart.data}
										collection={chart.request.collection}
										display={chart.request.attributesDisplay}
										actionViewHandle={items => actionViewHandle(items, chart.request.collection)}
										actionCheckHandle={(items, event) =>
											actionCheckHandle(items, event, chart.request.collection)
										}
										attributeMyCode={attributeMyCode}
									/>
								</MDBox>
								{openPannel && (
									<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
										<MDBox className="rightPannel" sx={{ ml: 2 }}>
											<Card sx={{ p: 2, height: "100%" }}>
												<MDBox display="flex" alignItems="center" justifyContent="space-between">
													<MDBox>Message JSON :</MDBox>
													<MDBox>
														<Tooltip placement="top" title="Fermer">
															<IconButton onClick={() => closePannel()}>
																<Icon>close</Icon>
															</IconButton>
														</Tooltip>
													</MDBox>
												</MDBox>

												<MDBox
													style={{
														height: "100%",
														overflowY: " auto"
													}}
												>
													<MDTypography variant="h6">
														{Object.keys(selectedJSON).length !== 0 && (
															<>
																<Tooltip placement="right" title="Copier le JSON">
																	<IconButton onClick={() => copyToClipboard()}>
																		<ContentCopyIcon />
																	</IconButton>
																</Tooltip>
																<JSONTree data={selectedJSON} theme={{ base00: "none" }} />
															</>
														)}
													</MDTypography>
												</MDBox>
											</Card>
										</MDBox>
									</MDBox>
								)}
							</MDBox>
						);
					})}
			</DashboardLayout>
		);
}

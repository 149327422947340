import "./style.css";

import {
	Alert,
	Box,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	Tooltip,
	Typography
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import RefreshIcon from "@mui/icons-material/Refresh";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import lod_ from "lodash";
import ReactJson from "react-json-view";
import MyDropzone from "./MyDropzone";

const FormDictionaryJSON = ({ submit, pageCatalog, catalogs }) => {
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [code, setCode] = useState("");
	const [catalog, setCatalog] = useState(pageCatalog ?? "none");

	const [jsonData, setJsonData] = useState({});
	const [oldFormat, setOldFormat] = useState("");

	const [fileError, setFileError] = useState("");
	const [showWarning, setShowWarning] = useState(false);

	const [successJSON, setSuccessJSON] = useState(true);

	const [tab, setTab] = useState(0);

	useEffect(() => {
		if (code.trim() !== "" && name.trim() !== "") {
			submit({
				valid: true,
				data: {
					name,
					code,
					description,
					catalog: catalog === "none" ? null : catalog,
					jsonData,
					oldFormat
				}
			});
		} else {
			submit({
				valid: false
			});
		}
	}, [name, code, description, jsonData, oldFormat, catalog]);

	const checkCodeItem = text => {
		let regex = /^[A-Za-z]+$/;
		if (regex.test(text)) {
			setCode(text);
		} else if (text === "") {
			setCode(text);
		} else {
			setShowWarning(true);
			setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}
	};

	const onChangeInputs = e => {
		let value = e.updated_src;
		setJsonData(value);
	};

	return (
		<MDBox>
			<MDTypography variant="h6">{i18n.t("SETTINGS.DICTIONARY.newDictionary")}</MDTypography>
			<MDBox display="flex" flexDirection="column" mt={1}>
				{/* Dictionary name */}
				<MDInput
					className="dialogInput"
					value={name}
					onChange={e => setName(e.target.value)}
					label={
						<span>
							{i18n.t("SETTINGS.DICTIONARY.dictionaryName")}
							<span className="mandatoryField">*</span>
						</span>
					}
				/>
				{/* Dictionary code */}
				<MDBox mt={1}>
					<Tooltip
						open={showWarning}
						title={i18n.t("SETTINGS.RULES.regexCode")}
						placement="right"
						disableFocusListener
						disableHoverListener
						disableTouchListener
					>
						<MDInput
							className="dialogInput"
							value={code}
							onChange={e => checkCodeItem(e.target.value)}
							label={
								<span>
									{i18n.t("SETTINGS.DICTIONARY.dictionaryCode")}
									<span className="mandatoryField">*</span>
								</span>
							}
						/>
					</Tooltip>
				</MDBox>
				{/* Dictionary description */}
				<MDBox mt={1}>
					<MDInput
						className="dialogInput"
						value={description}
						onChange={e => setDescription(e.target.value)}
						label={i18n.t("SETTINGS.DICTIONARY.dictionaryDescription")}
					/>
				</MDBox>
				{/* Target catalog */}
				<FormControl sx={{ mt: 1 }}>
					<InputLabel id="select-catalog-label">Catalogue</InputLabel>
					<Select
						labelId="select-catalog-label"
						id="select-catalog"
						label="Catalogue"
						value={catalog}
						onChange={e => {
							setCatalog(e.target.value);
						}}
					>
						<MenuItem value="none">Aucun</MenuItem>
						{catalogs.map((catalog, index) => (
							<MenuItem key={index} value={catalog.code}>
								{catalog.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<Tabs
					sx={{ mt: 1 }}
					value={tab}
					onChange={(e, newValue) => {
						setJsonData({});
						setOldFormat("");
						setTab(newValue);
					}}
				>
					<Tab label="Importer depuis ancien format" />
					<Tab label="Importer depuis JSON" />
				</Tabs>

				{/* Old format */}
				{tab === 0 && (
					<MDInput
						sx={{ mt: 1 }}
						fullWidth
						multiline
						rows={10}
						label="Ancien format"
						placeholder="Coller l'ancien format ici"
						value={oldFormat}
						onChange={e => {
							setOldFormat(e.target.value);
						}}
					/>
				)}

				{/* JSON */}
				{tab === 1 && (
					<Box sx={{ textAlign: "center", my: 4 }}>
						<Box>
							<Typography variant="h4" gutterBottom>
								{i18n.t("SETTINGS.DICTIONARY.jsonUpload")}
							</Typography>
							<MyDropzone
								setSuccessJSON={setSuccessJSON}
								setFileError={setFileError}
								setJsonData={setJsonData}
							/>
						</Box>

						{!successJSON && (
							<Alert severity="error" sx={{ mt: 2 }}>
								{fileError}
							</Alert>
						)}

						<Box sx={{ my: 3 }}>
							<Box
								style={{
									textAlign: "left"
								}}
							>
								<Tooltip title={i18n.t("SETTINGS.DICTIONARY.jsonReset")} placement="top">
									<IconButton
										style={{
											fontSize: "medium"
										}}
										disabled={lod_.isEmpty(jsonData)}
										onClick={() => setJsonData({})}
									>
										<RefreshIcon />
									</IconButton>
								</Tooltip>
							</Box>
							<ReactJson
								src={jsonData}
								name={null}
								style={{
									fontSize: "medium",
									textAlign: "left"
								}}
								enableClipboard={false}
								displayDataTypes={false}
								onEdit={onChangeInputs}
								onAdd={onChangeInputs}
								onDelete={onChangeInputs}
							/>
						</Box>
					</Box>
				)}
			</MDBox>
		</MDBox>
	);
};

export default FormDictionaryJSON;

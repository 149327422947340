/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/**
 * #######################################################@
 *
 * Button opening menu with items
 *
 * This component is used to select items in a menu
 *
 * #######################################################@
 */
import Checkbox from "@mui/material/Checkbox";
import { Badge, Button, Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";

function Item({ item, isSelected, handleSelectItem }) {
	function handleClick() {
		handleSelectItem(item);
	}

	return (
		<MenuItem
			onClick={() => {
				handleClick();
			}}
		>
			<Checkbox checked={Boolean(isSelected)} />
			<MDTypography sx={{ ml: 1 }} variant="h6">
				{item.label}
			</MDTypography>
		</MenuItem>
	);
}

function ButtonMenu({
	items = [],
	handleUpdate,
	title,
	fullHeight = false,
	type = null,
	inverted = false,
	optionnal = null,
	...rest
}) {
	const [selectedItems, setSelectedItems] = useState([]);

	if (type === "boolean") {
		if (inverted) {
			items = [
				{
					code: "yes",
					label: "Oui",
					value: false
				},
				{
					code: "no",
					label: "Non",
					value: true
				}
			];
		} else {
			items = [
				{
					code: "yes",
					label: "Oui",
					value: true
				},
				{
					code: "no",
					label: "Non",
					value: false
				}
			];
		}

		if (optionnal) {
			optionnal.map(item => {
				let obj = items.find(i => i.code === item);
				obj.value = { $in: [obj.value, null] };
			});
		}
	}

	function handleSelectItem(item) {
		if (type === "boolean") {
			if (selectedItems.code === item.code) {
				setSelectedItems([]);
			} else {
				setSelectedItems(item);
			}
		} else {
			if (selectedItems.find(selectedItem => selectedItem.value === item.value)) {
				setSelectedItems(selectedItems.filter(selectedItem => selectedItem.value !== item.value));
			} else {
				setSelectedItems([...selectedItems, item]);
			}
		}
	}

	useEffect(() => {
		handleUpdate(selectedItems);
	}, [selectedItems]);

	const [menuRef, setMenuRef] = useState(null);
	const open = Boolean(menuRef);

	let style = {};

	if (fullHeight) {
		style.height = "100%";
	}

	return (
		<>
			<Badge
				/* badgeContent={selectedItems.length ? " " : null} */
				badgeContent={selectedItems.length}
				color="info"
				style={style}
			>
				<MDButton
					{...rest}
					variant="contained"
					color="dark"
					onClick={e => {
						setMenuRef(e.currentTarget);
					}}
					// startIcon={<Icon>flag</Icon>}
				>
					<Icon>filter_alt</Icon>&nbsp;{title}
				</MDButton>
			</Badge>

			<Menu
				open={open}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				<MDBox mb={1}>
					<MenuItem
						onClick={() => {
							if (selectedItems.length) {
								setSelectedItems([]);
							} else {
								setSelectedItems(items);
							}
						}}
					>
						<MDTypography variant="h6">{i18n.t("FILTERS.menu.selectUnselect")}</MDTypography>
					</MenuItem>
				</MDBox>
				{items.map((item, key) => {
					return (
						<Item
							key={key}
							item={item}
							handleSelectItem={handleSelectItem}
							isSelected={
								type === "boolean"
									? selectedItems.code === item.code
									: selectedItems.find(selectedItem => selectedItem?.value === item?.value)
							}
						/>
					);
				})}
			</Menu>
		</>
	);
}

export default ButtonMenu;

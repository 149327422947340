/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Page settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tooltip
} from "@mui/material";
import lod_ from "lodash";
import { useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import C from "../charts/steps/constants";
import IconAccordion from "../charts/steps/components/IconAccordion";
import DictionaryMenu from "../filters/DictionaryMenu";

export default function AddPageDialog({
	open,
	handleCloseDialog,
	handleSave,
	rank,
	dictionary,
	filtersList = {}
}) {
	const [name, setName] = useState(null);
	const [routeDictLabel, setRouteDictLabel] = useState(null);
	const [type, setType] = useState("route");
	const [icon, setIcon] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	// const [form, setForm] = useState(false);
	const [collection, setCollection] = useState(Object.keys(dictionary));

	const [form, setForm] = useState({
		active: true,
		collectionDefault: null,
		pageLabel: null,
		routeDictionary: null,
		dbName: null
	});

	const [filterCode, setFilterCode] = useState(null);

	const disabledForType = type => {
		switch (type) {
			case "route":
				if (!name) {
					return true;
				}
				break;
			case "form":
				if (!form.collectionDefault || form.collectionDefault === "none") {
					return true;
				}
				break;
			case "title":
				if (!name) {
					return true;
				}
				break;
			case "divider":
				return false;
			default:
				return false;
		}

		return false;
	};

	const isDisabled = disabledForType(type);

	function close() {
		handleCloseDialog();
		setName(null);
		setType("route");
		setFilterCode(null);
		setRouteDictLabel("");
		setForm({
			active: true,
			collectionDefault: null,
			pageLabel: null,
			routeDictionary: null,
			dbName: null
		});
	}

	function sumbit() {
		let returnObject = {};

		switch (type) {
			case "route":
				returnObject = {
					rank,
					type: "route",
					name,
					route: `/${name.toLowerCase().trim().replaceAll(" ", "-")}`,
					icon,
					filter: filterCode
				};
				break;
			case "form":
				returnObject = {
					rank,
					type: "route",
					name,
					route: `/${name.toLowerCase().trim().replaceAll(" ", "-")}`,
					icon,
					form,
					filter: filterCode
				};
				break;
			case "title":
				returnObject = {
					rank,
					type,
					name
				};
				break;
			case "divider":
				returnObject = {
					rank,
					type
				};
				break;
			default:
				break;
		}

		handleSave(returnObject);
		close();
	}

	function getFiltersDetails() {
		if (!filterCode || !filtersList.filters) return "";
		let filter = filtersList.find(filter => filter.code === filterCode);
		let detail = filter.filters.map(filter => filter.fr).join(" / ");
		return detail;
	}

	const addItem = item => {
		let route = item.replaceAll(".", ".items.");
		setForm({ ...form, routeDictionary: route });
		let itemLabel = lod_.get(dictionary, route)?.label?.fr;
		setAnchorEl(null);
		setRouteDictLabel(itemLabel);
	};

	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={close}>
			<DialogTitle>{i18n.t("SETTINGS.PAGES.addPage")}</DialogTitle>
			<DialogContent>
				<MDBox mt={1}>
					<FormControl fullWidth>
						<InputLabel
							style={{
								padding: "12px !important"
							}}
							id="select-type"
						>
							{i18n.t("SETTINGS.PAGES.type")}
						</InputLabel>
						<Select
							labelId="select-type"
							value={type}
							label={i18n.t("SETTINGS.PAGES.type")}
							onChange={e => setType(e.target.value)}
						>
							<MenuItem value="route">{i18n.t("SETTINGS.PAGES.route")}</MenuItem>
							<MenuItem value="form">Form</MenuItem>
							<MenuItem value="title">{i18n.t("SETTINGS.PAGES.title")}</MenuItem>
							<MenuItem value="divider">{i18n.t("SETTINGS.PAGES.divider")}</MenuItem>
						</Select>
					</FormControl>
				</MDBox>

				{type !== "divider" && (
					<MDBox mt={1}>
						<MDInput
							className="dialogInput"
							label={i18n.t("SETTINGS.PAGES.name")}
							onChange={e => setName(e.target.value)}
						/>
					</MDBox>
				)}
				{(type === "route" || type === "form") && (
					<>
						<MDBox mt={2}>
							<MDTypography variant="h6">Icon</MDTypography>
						</MDBox>
						<MDBox
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center"
							}}
						>
							{icon && <Icon fontSize="large">{icon}</Icon>}
							{!icon && (
								<MDTypography
									variant="h6"
									style={{
										fontStyle: "italic"
									}}
								>
									{i18n.t("SETTINGS.PAGES.noIcon")}
								</MDTypography>
							)}
							<div>
								<Tooltip placement="top" title={i18n.t("SETTINGS.PAGES.deleteIcon")}>
									<IconButton
										color="error"
										onClick={() => {
											setIcon(null);
										}}
									>
										<Icon fontSize="large">close</Icon>
									</IconButton>
								</Tooltip>
							</div>
						</MDBox>

						<IconAccordion
							title={i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
							open
							content={C.ICONS_LIST.map((icon, index) => {
								return (
									<IconButton
										key={index}
										onClick={() => {
											setIcon(icon);
										}}
									>
										<Icon fontSize="large">{icon}</Icon>
									</IconButton>
								);
							})}
						/>

						<MDBox mt={2}>
							<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.filter")}</MDTypography>
						</MDBox>

						<MDBox mt={1}>
							<FormControl fullWidth>
								<InputLabel id="select-type">{i18n.t("SETTINGS.PAGES.filter")}</InputLabel>
								<Select
									labelId="select-type"
									value={filterCode || "none"}
									label={i18n.t("SETTINGS.PAGES.filter")}
									onChange={e => {
										if (e.target.value === "none") setFilterCode(null);
										else setFilterCode(e.target.value);
									}}
								>
									<MenuItem value="none">{i18n.t("SETTINGS.PAGES.none")}</MenuItem>
									{filtersList &&
										filtersList.map(filter => {
											return <MenuItem value={filter.code}>{filter.name}</MenuItem>;
										})}
								</Select>
							</FormControl>
						</MDBox>

						<MDBox mt={1}>
							<MDTypography variant="body2">{getFiltersDetails()}</MDTypography>
						</MDBox>

						{type === "form" && (
							<>
								<MDBox mt={2}>
									<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.form")}</MDTypography>
								</MDBox>

								<MDBox mt={1}>
									<MDInput
										className="dialogInput"
										label="Base de donnée"
										onChange={e =>
											setForm({
												...form,
												dbName: e.target.value
											})
										}
									/>
								</MDBox>

								<MDBox mt={1}>
									<FormControl fullWidth>
										<InputLabel id="select-label">Collection</InputLabel>
										<Select
											labelId="select-collection-form"
											value={form.collectionDefault || "none"}
											label="Collection"
											onChange={e => {
												setForm({
													...form,
													collectionDefault: e.target.value
												});
											}}
										>
											<MenuItem value="none">Aucune</MenuItem>
											{collection &&
												collection.map(filter => {
													return <MenuItem value={filter}>{filter}</MenuItem>;
												})}
										</Select>
									</FormControl>
								</MDBox>

								<MDBox mt={2}>
									<MDInput
										className="dialogInput"
										label="Label de page"
										onChange={e =>
											setForm({
												...form,
												pageLabel: e.target.value
											})
										}
									/>
								</MDBox>
								<MDBox mt={2}>
									<MDTypography variant="h6">Objet source</MDTypography>
								</MDBox>
								<MDBox mt={2}>
									<MDBox display="flex" alignItems="center">
										<MDButton variant="gradient" color="info" onClick={e => setAnchorEl(e.target)}>
											{i18n.t("SETTINGS.add")}
										</MDButton>
										<DictionaryMenu
											levelLayer
											dictionary={dictionary}
											anchorEl={anchorEl}
											handleInsertText={addItem}
											handleClose={() => setAnchorEl(null)}
										/>
										<MDBox display="flex" ml={1}>
											<MDTypography variant="body2">
												{routeDictLabel ?? "Aucune selection"}
											</MDTypography>
										</MDBox>
									</MDBox>
								</MDBox>
							</>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled} variant="contained" color="dark" onClick={sumbit}>
					{i18n.t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}

/**
 * Websocket message handler
 * @param {*} store
 * @param {*} socket
 * @param {*} event
 * @param {*} data
 */
export default function receiveWebsocketMessage({ dispatch, getState }, socket, event, data) {
	switch (event) {
		default:
			break;
	}
}

/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import { Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import { DateTime } from "luxon";
import i18n from "i18n";

const PresetComponent = ({ preset, handleSelectPreset }) => {
	function getInterval(code) {
		let interval = {};
		switch (code) {
			/**
			 * Last week :
			 *
			 * /!\ From Sunday to Saturday
			 */
			case "lastWeek":
				let sunday = DateTime.local()
					.minus({ days: 7 })
					.startOf("week")
					// .minus({ days: 1 })
					.toJSDate();

				let saturday = DateTime.local()
					.minus({ days: 7 })
					.endOf("week")
					// .minus({ days: 1 })
					.toJSDate();

				interval = {
					$gte: sunday,
					$lte: saturday
				};
				break;
			/**
			 * Last month :
			 *
			 * From first day of the month to last day of the month
			 */
			case "lastMonth":
				let firstDay = DateTime.local().minus({ months: 1 }).startOf("month").toJSDate();
				let lastDay = DateTime.local().minus({ months: 1 }).endOf("month").toJSDate();

				interval = {
					$gte: firstDay,
					$lte: lastDay
				};
				break;
			/**
			 * Last quarter :
			 *
			 * From first day of the quarter to last day of the quarter
			 *
			 *    1er Janvier  --> 31 Mars
			 *    1er Avril    --> 30 Juin
			 *    1er Juillet  --> 30 Septembre
			 *    1er Octobre  --> 31 Décembre
			 */
			case "lastQuarter":
				let firstDayOfQuarter = DateTime.local().minus({ months: 3 }).startOf("quarter").toJSDate();
				let lastDayOfQuarter = DateTime.local().minus({ months: 3 }).endOf("quarter").toJSDate();

				interval = {
					$gte: firstDayOfQuarter,
					$lte: lastDayOfQuarter
				};
				break;
			/**
			 *
			 * Mtd (Month to date)
			 *
			 * From first day of the month to today
			 */
			case "mtd":
				let firstDayOfCurrentMonth = DateTime.local().startOf("month").toJSDate();
				let lastDayOfCurrentMonth = DateTime.local().toJSDate();

				interval = {
					$gte: firstDayOfCurrentMonth,
					$lte: lastDayOfCurrentMonth
				};
				break;
			/**
			 *
			 * Qtd (Quarter to date)
			 *
			 * From first day of the quarter to today
			 */
			case "qtd":
				let firstDayOfCurrentQuarter = DateTime.local().startOf("quarter").toJSDate();
				let lastDayOfCurrentQuarter = DateTime.local().toJSDate();

				interval = {
					$gte: firstDayOfCurrentQuarter,
					$lte: lastDayOfCurrentQuarter
				};
				break;
			/**
			 *
			 * Ytd (Year to date)
			 *
			 * From first day of the year to today
			 */
			case "ytd":
				let firstDayOfCurrentYear = DateTime.local().startOf("year").toJSDate();
				let lastDayOfCurrentYear = DateTime.local().toJSDate();

				interval = {
					$gte: firstDayOfCurrentYear,
					$lte: lastDayOfCurrentYear
				};
				break;
			/**
			 * Lifestime
			 *
			 * From first day of the year to today
			 */
			case "lifestime":
				interval = {
					$gte: null,
					$lte: null
				};
				break;
			default:
				interval = {
					$gte: null,
					$lte: null
				};
				break;
		}

		return interval;
	}

	return (
		<MenuItem
			onClick={() => {
				let interval = getInterval(preset.code);
				handleSelectPreset(interval);
			}}
		>
			<MDTypography sx={{ ml: 1 }} variant="h6">
				{preset.name}
			</MDTypography>
		</MenuItem>
	);
};

function DatePresets({ handleSelectPreset, fullHeight, ...rest }) {
	const [menuRef, setMenuRef] = useState(null);
	const open = Boolean(menuRef);

	function handleSelection(preset) {
		setMenuRef(null);
		handleSelectPreset(preset);
	}

	let style = {};

	if (fullHeight) {
		style.height = "100%";
	}

	let presets = [
		{
			name: i18n.t("FILTERS.date.PRESETS.lastWeek"),
			code: "lastWeek"
		},
		{
			name: i18n.t("FILTERS.date.PRESETS.lastMonth"),
			code: "lastMonth"
		},
		{
			name: i18n.t("FILTERS.date.PRESETS.lastQuarter"),
			code: "lastQuarter"
		},
		{
			name: i18n.t("FILTERS.date.PRESETS.mtd"),
			code: "mtd"
		},
		{
			name: i18n.t("FILTERS.date.PRESETS.qtd"),
			code: "qtd"
		},
		{
			name: i18n.t("FILTERS.date.PRESETS.ytd"),
			code: "ytd"
		},
		{
			name: i18n.t("FILTERS.date.PRESETS.lifetime"),
			code: "lifestime"
		}
	];

	return (
		<>
			<MDButton
				{...rest}
				style={style}
				variant="contained"
				color="dark"
				onClick={e => {
					setMenuRef(e.currentTarget);
				}}
				// startIcon={<Icon>calendar_today</Icon>}
			>
				<Icon>calendar_today</Icon>
			</MDButton>

			<Menu
				open={open}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				{presets.map((preset, key) => {
					return <PresetComponent key={key} preset={preset} handleSelectPreset={handleSelection} />;
				})}
			</Menu>
		</>
	);
}

DatePresets.defaultProps = {
	fullHeight: false
};

export default DatePresets;

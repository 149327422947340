/**
 * Main application dashboard
 */
import MDBox from "components/Basics/MDBox";

import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import MDTypography from "components/Basics/MDTypography";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";

export default function Dashboard() {
	return (
		<DashboardLayout>
			<MDBox py={3}>
				<DashboardNavbar />
				<MDTypography>Main dashboard</MDTypography>
			</MDBox>
		</DashboardLayout>
	);
}

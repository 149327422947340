/* eslint-disable no-unreachable */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import { useEffect, useRef, useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { Fade, Grow, Icon, IconButton, Menu, MenuItem } from "@mui/material";
import i18n from "i18n";
import FiltersTable from "./FiltersTable";
import C from "../constants";
/**
 * Component to select value in dictionary
 */
const DictionarySelection = ({ empty, title, text, dictionary, update }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = e => {
		setAnchorEl(null);
		update(e);
	};

	return (
		<MDBox mt={4}>
			<MDTypography variant="h3">{title}</MDTypography>
			<MDBox display="flex" m={1}>
				<MDBox
					borderRadius="lg"
					shadow="lg"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					bgColor="light"
					sx={{ pr: 1, width: "40vw" }}
				>
					<MDBox display="flex" alignItems="center">
						<MDButton
							variant="gradient"
							color="info"
							onClick={e => setAnchorEl(e.target)}
							sx={{ mr: 1 }}
						>
							<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.choose")}
						</MDButton>
						<MDTypography variant="h6" fontSize="medium">
							{text}
						</MDTypography>
					</MDBox>
					<MDBox display="flex" alignItems="center">
						<Icon color={empty ? "error" : "success"} fontSize="medium">
							{empty ? "close" : "check_circle_outline"}
						</Icon>
					</MDBox>
				</MDBox>
			</MDBox>
			<DictionaryMenu
				placement="right"
				dictionary={dictionary}
				anchorEl={anchorEl}
				handleInsertText={handleClick}
				handleClose={() => setAnchorEl(null)}
			/>
		</MDBox>
	);
};
/**
 * Main component
 */
const DataChoiceStep = ({
	chart,
	handleSubmitDatas,
	handleSubmitFilters,
	handleSubmitOptions,
	collections,
	validStep
}) => {
	const [request, setRequest] = useState({});
	const [filters, setFilters] = useState({});
	const [options, setOptions] = useState({});
	const [dictionary, setDictionary] = useState({});
	// anchors
	const [binningMenuEl, setBinningMenuEl] = useState(null);
	const [anchorGroupEl, setAnchorGroupEl] = useState(null);
	// display when value is selected
	const [selectedAttribute, setSelectedAttribute] = useState(null);
	const [selectedGroup, setSelectedGroup] = useState(null);
	// value states
	const [selectedColumnAttribute, setSelectedColumnAttribute] = useState(null);
	const [selectedRowAttribute, setSelectedRowAttribute] = useState(null);
	const [selectedDateAttribute, setSelectedDateAttribute] = useState(null);
	// manage binning state
	const [binning, setBinning] = useState([]);

	const [filtersArray, setFiltersArray] = useState([]);
	const scrollEnd = useRef(null);

	const [step, setStep] = useState(0);
	// All differents steps to create a charts
	const PARTS_ORDER = [
		{
			name: "collection",
			reset: null,
			step: 1
		},
		{
			name: "computeMethod",
			reset: null,
			step: 2
		},
		{
			name: "attribute",
			reset: setSelectedAttribute,
			def: null,
			step: 3
		},
		{
			name: "dateAttribute",
			reset: setSelectedDateAttribute,
			def: null,
			step: 4
		},
		{
			name: "binning",
			reset: setBinning,
			def: [],
			step: 4
		},
		{
			name: "col",
			reset: setSelectedColumnAttribute,
			def: null,
			step: 5
		},
		{
			name: "row",
			reset: setSelectedRowAttribute,
			def: null,
			step: 6
		},
		{
			name: "group",
			reset: setSelectedGroup,
			def: null,
			step: 7
		},
		{
			name: "filters",
			reset: setFiltersArray,
			def: [],
			step: 8
		}
	];
	// Scroll to end of page
	function scrollToEnd() {
		setTimeout(() => {
			scrollEnd.current?.scrollIntoView({ behavior: "smooth" });
		}, 100);
	}
	// Handle when choosing a data source
	const updateCollection = collection => {
		setRequest(req => {
			let nReq = {
				...req,
				collection: collection.collection
			};
			delete nReq.computeMethod;
			delete nReq.attribute;
			delete nReq.group;
			setSelectedAttribute(null);
			setSelectedGroup(null);
			return nReq;
		});
		setDictionary(collection.dictionary);
		setStep(1);

		scrollToEnd();
	};
	// Handle when choosing computing method
	const updateMethod = method => {
		setRequest(req => {
			let nReq = {
				...req,
				computeMethod: method.code
			};
			delete nReq.attribute;
			delete nReq.group;
			setSelectedAttribute(null);
			setSelectedGroup(null);
			return nReq;
		});
		if (method.code === "COUNT" && chart.type === "oneValue") {
			setStep(5);
			validStep();
		} else if (method.code === "COUNT") {
			setStep(3);
		} else {
			setStep(2);
		}

		scrollToEnd();
	};
	// Default update function for all other steps
	const updateRequest = (attribute, name, setAttribute, steper) => {
		// get attribute from dictionary
		let realPath = attribute.replaceAll(".", ".items.");
		let dicObject = lod_.get(dictionary, realPath);

		let fieldInDic = attribute.split(".");

		if (fieldInDic.length > 1) {
			fieldInDic = attribute.split(".").slice(1).join(".");
		} else {
			fieldInDic = fieldInDic[0];
		}

		// update request
		setRequest(req => {
			// update field in request object with new value
			let nReq = {
				...req,
				[name]: fieldInDic
			};
			// get all parts to delete (further fields)
			let toDelete = PARTS_ORDER.slice(
				PARTS_ORDER.indexOf(PARTS_ORDER.find(p => p.name === name)) + 1
			);
			let actualStep = PARTS_ORDER.find(p => p.name === name).step;
			// delete all selected further fields if setted
			toDelete.map(el => {
				let { name, reset, def, step } = el;
				if (actualStep === step) return;
				if (nReq[name]) delete nReq[name];
				if (reset) {
					reset(def);
				}
			});

			return nReq;
		});
		// set selected attribute
		setAttribute(dicObject.label.fr);
		// set next step
		steper();
		scrollToEnd();
	};
	// Handle when select an "attribute"
	const updateAttribute = attribute => {
		let realPath = attribute.replaceAll(".", ".items.");
		let dicObject = lod_.get(dictionary, realPath);
		if (dicObject.type === "timestamp") {
			setOptions({
				formatDate: true
			});
		} else {
			setOptions({});
		}
		const steper = () => {
			if (chart.type === "oneValue") {
				validStep();
				setStep(6);
			} else {
				setStep(3);
			}
		};
		updateRequest(attribute, "attribute", setSelectedAttribute, steper);
	};
	// Handle when select a "group"
	const updateGroup = attribute => {
		const steper = () => {
			setStep(5);
			validStep();
		};
		updateRequest(attribute, "group", setSelectedGroup, steper);
	};
	// Handle when select a "row"
	const updateRowCrossTable = attribute => {
		const steper = () => {
			setStep(5);
			validStep();
		};
		updateRequest(attribute, "row", setSelectedRowAttribute, steper);
	};
	// Handle when select a "col"
	const updateColumnCrossTable = attribute => {
		const steper = () => {
			setStep(4);
		};
		updateRequest(attribute, "col", setSelectedColumnAttribute, steper);
	};
	// Handle when select a "dateAttribute"
	const updateDateAttribute = attribute => {
		const steper = () => {};
		updateRequest(attribute, "dateAttribute", setSelectedDateAttribute, steper);
	};
	// Handle when user add an element to binning
	const updateBinning = code => {
		let newBinning = lod_.clone(binning);
		newBinning.push(code);
		setBinning(newBinning);
		scrollToEnd();
	};
	// Handle when user remove an element to binning
	const removeBinning = index => {
		let newBinning = lod_.clone(binning);
		if (newBinning.length < index) return;
		newBinning.splice(index, 1);
		setBinning(newBinning);
		scrollToEnd();
	};
	/**
	 * Parse dictionary to get available items by type
	 * @param {[String]} value like : ["number"]
	 */
	function parseTypeDictionary(arrayValues, dictionary) {
		let availableItems = {};
		// Map key of object
		for (let key in dictionary) {
			let actualLevel = dictionary[key];
			if (actualLevel.type === "level") {
				// Branch
				let result = parseTypeDictionary(arrayValues, actualLevel.items);
				// If we have some results we add the branch to the return
				if (!lod_.isEmpty(result)) {
					let copy = lod_.cloneDeep(actualLevel);
					copy.items = result;
					availableItems[key] = copy;
				}
			} else {
				// When item is sheet (end of the branch)
				if (arrayValues.includes(actualLevel.type)) {
					availableItems[key] = actualLevel;
				}
			}
		}
		return availableItems;
	}
	// When user update a filter, save it
	const onChangeValue = ({ name, filter, method, value }) => {
		if (method && value) {
			let mongoFilter = {
				[name]: {
					[method]: value
				}
			};

			let newFilters = {
				...filters,
				...mongoFilter
			};

			let updatedFilter = {
				filter,
				name,
				method,
				value
			};

			let updatedFiltersArray = filtersArray.map(f => {
				if (f.name === name) {
					return updatedFilter;
				} else {
					return f;
				}
			});

			setFiltersArray(updatedFiltersArray);
			setFilters(newFilters);
		}
	};
	// When user want to add a filter
	const handleAddFilter = filter => {
		let realPath = filter.replaceAll(".", ".items.");
		let dicObject = lod_.get(dictionary, realPath);
		let name = filter.split(".")[filter.split(".").length - 1];
		// user can add only one filter by attribute
		let existInArray = filtersArray.find(f => f.name === name);

		if (dicObject && !existInArray) {
			let copy = [
				...filtersArray,
				{
					filter: dicObject,
					name
				}
			];

			setFiltersArray(copy);
		}

		scrollToEnd();
	};
	// When user want to remove a filter
	const handleRemoveFilter = name => {
		let filteredArray = filtersArray.filter(f => f.name !== name);
		setFiltersArray(filteredArray);

		let filteredFilters = lod_.cloneDeep(filters);
		delete filteredFilters[name];

		setFilters(filteredFilters);
	};
	// When user add / remove binning
	useEffect(() => {
		setRequest(req => {
			return {
				...req,
				binning
			};
		});
		if (chart.type === "timeSeries") {
			if (binning.length > 0 && selectedDateAttribute) {
				setStep(4);
			}
			if ((binning.length === 0 || !selectedDateAttribute) && step > 3) {
				setStep(3);
			}
		}
	}, [binning, selectedDateAttribute]);
	// Used when user change data source / method / attribute / group
	useEffect(() => {
		handleSubmitDatas(request);
	}, [request]);
	// Used when user add / remove filter
	useEffect(() => {
		handleSubmitFilters(filters);
	}, [filters]);
	// Used when user add / remove options
	useEffect(() => {
		handleSubmitOptions(options);
	}, [options]);
	// On page load
	useEffect(() => {
		validStep(false);
	}, []);

	return (
		<MDBox>
			{/* Choose collection, mandatory for eveything */}
			<MDBox>
				<MDTypography variant="h3">
					{i18n.t("SETTINGS.CHARTS.NEW.dataSourceSelection")}
				</MDTypography>
				<MDBox display="flex">
					{collections.map((collection, index) => {
						let active = request.collection === collection.collection;
						return (
							<Grow key={index} in timeout={(index + 1) * 200}>
								<div>
									<MDBox m={1} key={index}>
										<MDButton
											variant="gradient"
											shadow="lg"
											color={active ? "secondary" : "light"}
											onClick={() => updateCollection(collection)}
										>
											{collection.label}
										</MDButton>
									</MDBox>
								</div>
							</Grow>
						);
					})}
				</MDBox>
			</MDBox>
			{/* After collection, choose compute method, mandatory for everything */}
			{step > 0 && (
				<Fade in>
					<MDBox mt={4}>
						<MDTypography variant="h3">
							{i18n.t("SETTINGS.CHARTS.NEW.computeMethodSelection")}
						</MDTypography>
						<MDBox display="flex">
							{C.COMPUTE_METHOD.map((method, index) => {
								let active = request.computeMethod === method.code;
								return (
									<Grow key={index} in timeout={(index + 1) * 200}>
										<div>
											<MDBox m={1} key={index}>
												<MDButton
													variant="gradient"
													shadow="lg"
													color={active ? "secondary" : "light"}
													onClick={() => updateMethod(method)}
												>
													{i18n.t(method.label)}
												</MDButton>
											</MDBox>
										</div>
									</Grow>
								);
							})}
						</MDBox>
					</MDBox>
				</Fade>
			)}
			{/* ################################################ */}
			{/*	PART FOR : ONEVALUE / ONEDIMENSION / PROPORTION  */}
			{/* ################################################ */}
			{["oneValue", "oneDimension", "proportion"].includes(chart.type) && (
				<>
					{/* Choose the field to compute, mandatory for everything except count */}
					{step > 1 && request.computeMethod !== "COUNT" && (
						<Fade in>
							<div>
								<DictionarySelection
									title={i18n.t("SETTINGS.CHARTS.NEW.computeAttributeSelection")}
									text={selectedAttribute ?? i18n.t("SETTINGS.CHARTS.NEW.noAttributeSelected")}
									dictionary={dictionary}
									// dictionary={parseTypeDictionary(["number"], dictionary)}
									update={updateAttribute}
									empty={!selectedAttribute}
								/>
							</div>
						</Fade>
					)}
					{/* Choose the group by field, mandatory for everything and oneValue's charts */}
					{step > 2 && chart.type !== "oneValue" && (
						<Fade in>
							<div>
								<DictionarySelection
									title={i18n.t("SETTINGS.CHARTS.NEW.groupBy")}
									text={selectedGroup ?? i18n.t("SETTINGS.CHARTS.NEW.groupBy")}
									dictionary={dictionary}
									update={updateGroup}
									empty={!selectedGroup}
								/>
							</div>
						</Fade>
					)}
				</>
			)}
			{/* ################################################ */}
			{/*	PART FOR : CROSSTABLE / TIMESERIES               */}
			{/* ################################################ */}
			{["crossTable", "timeSeries"].includes(chart.type) && (
				<>
					{/* Choose the atribute to compute, mandatory for everything except count */}
					{step > 1 && request.computeMethod !== "COUNT" && (
						<Fade in>
							<div>
								<DictionarySelection
									title={i18n.t("SETTINGS.CHARTS.NEW.computeAttributeSelection")}
									text={selectedAttribute ?? i18n.t("SETTINGS.CHARTS.NEW.noAttributeSelected")}
									dictionary={dictionary}
									// dictionary={parseTypeDictionary(["number"], dictionary)}
									update={updateAttribute}
									empty={!selectedAttribute}
								/>
							</div>
						</Fade>
					)}
					{/* Choose the col field attribute, ONLY FOR CROSS TABLE */}
					{step > 2 && chart.type === "crossTable" && (
						<Fade in>
							<div>
								<DictionarySelection
									title={i18n.t("SETTINGS.CHARTS.NEW.abcisseSelection")}
									text={
										selectedColumnAttribute ?? i18n.t("SETTINGS.CHARTS.NEW.noAttributeSelected")
									}
									dictionary={dictionary}
									update={updateColumnCrossTable}
									empty={!selectedColumnAttribute}
								/>
							</div>
						</Fade>
					)}
					{/* Choose the col field binning, ONLY FOR TIME SERIES */}
					{step > 2 && chart.type === "timeSeries" && (
						<MDBox display="flex">
							<MDBox flex="1" sx={{ width: "100%" }}>
								<Fade in>
									<div>
										<DictionarySelection
											title={i18n.t("SETTINGS.CHARTS.NEW.dateAttributeSelection")}
											text={
												selectedDateAttribute ?? i18n.t("SETTINGS.CHARTS.NEW.noAttributeSelected")
											}
											dictionary={parseTypeDictionary(["datetime"], dictionary)}
											update={updateDateAttribute}
											empty={!selectedDateAttribute}
										/>
									</div>
								</Fade>
							</MDBox>
							<MDBox flex="1" sx={{ width: "100%" }}>
								<Fade in>
									<MDBox mt={4}>
										<MDTypography variant="h3">
											{i18n.t("SETTINGS.CHARTS.NEW.binningSelection")}
										</MDTypography>
										<MDBox m={1} display="flex">
											<MDBox
												borderRadius="lg"
												shadow="lg"
												display="flex"
												alignItems="center"
												bgColor="light"
												sx={{ pr: 1, width: "100%" }}
											>
												<MDButton
													disabled={binning.length > 5}
													sx={{ mr: 2 }}
													variant="gradient"
													color="info"
													onClick={e => {
														setBinningMenuEl(e.target);
													}}
												>
													<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.add")}
												</MDButton>
												{!binning.length ? (
													<MDTypography variant="h6">
														{i18n.t("SETTINGS.CHARTS.NEW.noBinning")}
													</MDTypography>
												) : (
													binning.map((b, i) => {
														return (
															<MDBox
																position="relative"
																mr={1}
																key={i}
																borderRadius="lg"
																variant="gradient"
																display="flex"
																alignItems="center"
																bgColor="light"
																sx={{ pl: 1, height: "100%" }}
															>
																<MDTypography variant="h6">
																	{i18n.t(C.MONGODB_BINNING_FORMAT.find(f => f.code === b)?.label)}
																</MDTypography>
																<MDBox ml={1}>
																	<IconButton
																		// color="error"
																		onClick={() => {
																			removeBinning(i);
																		}}
																	>
																		<Icon fontSize="small">close</Icon>
																	</IconButton>
																</MDBox>
															</MDBox>
														);
													})
												)}
											</MDBox>
											<Menu
												open={Boolean(binningMenuEl)}
												anchorEl={binningMenuEl}
												onClose={() => setBinningMenuEl(null)}
											>
												{C.MONGODB_BINNING_FORMAT.map((b, i) => {
													return (
														<MenuItem
															key={i}
															onClick={() => {
																updateBinning(b.code);
																setBinningMenuEl(null);
															}}
														>
															{i18n.t(b.label)}
														</MenuItem>
													);
												})}
											</Menu>
										</MDBox>
									</MDBox>
								</Fade>
							</MDBox>
						</MDBox>
					)}
					{/* Choose the row fiels */}
					{step > 3 && (
						<Fade in>
							<div>
								<DictionarySelection
									title={i18n.t("SETTINGS.CHARTS.NEW.ordinateSelection")}
									text={selectedRowAttribute ?? i18n.t("SETTINGS.CHARTS.NEW.noAttributeSelected")}
									dictionary={dictionary}
									update={updateRowCrossTable}
									empty={!selectedRowAttribute}
								/>
							</div>
						</Fade>
					)}
				</>
			)}
			{/* Extra Filters, optional but must be display at the end */}
			{step > 4 && (
				<Fade in>
					<MDBox mt={4}>
						<MDTypography variant="h3">
							{i18n.t("SETTINGS.CHARTS.NEW.filtersSelection")}
						</MDTypography>
						<MDBox m={1} display="flex">
							<MDButton variant="gradient" color="info" onClick={e => setAnchorGroupEl(e.target)}>
								{i18n.t("SETTINGS.add")}
							</MDButton>
						</MDBox>
						<DictionaryMenu
							placement="right"
							dictionary={dictionary}
							anchorEl={anchorGroupEl}
							handleInsertText={handleAddFilter}
							handleClose={() => setAnchorGroupEl(null)}
						/>
						<FiltersTable
							filtersArray={filtersArray}
							handleRemoveFilter={handleRemoveFilter}
							onChangeValue={onChangeValue}
						/>
					</MDBox>
				</Fade>
			)}
			<div data-id="endoftable" ref={scrollEnd}></div>
		</MDBox>
	);
};

export default DataChoiceStep;

import { Fade, Grow, Zoom } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { useEffect } from "react";
import C from "../constants";
import InformationCard from "../components/InformationCard";
/**
 * Step 2: Selected the chart sub type
 * @param {*} param0
 * @returns
 */
const SubTypeChoiceStep = ({ chart, handleSelectSubType, validStep }) => {
	// Get sub types by type if exists
	const getSubType = type => {
		return C.CHARTS_SUBTYPE[type] ?? [];
	};
	// Handle select subtype
	const selectSubType = subType => {
		handleSelectSubType(subType);
		validStep();
	};

	useEffect(() => {
		if (chart?.display?.subType) {
			validStep();
		}
	}, []);

	return (
		<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
			{getSubType(chart.type).map((st, index) => {
				return (
					<Grow key={index} in timeout={(index + 1) * 500}>
						<div>
							<InformationCard
								element={st}
								onClick={() => selectSubType(st)}
								selected={chart && chart.display.subType === st.code}
							/>
						</div>
					</Grow>
				);
			})}
		</MDBox>
	);
};

export default SubTypeChoiceStep;

/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
import { Card, Divider, Icon, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import { JSONTree } from "react-json-tree";
import { useDispatch } from "react-redux";
import FormAction from "redux-react/actions/formAction";

const RightPannel = ({ closePannel, content }) => {
	const dispatch = useDispatch();

	const client = content.client;
	const collection = content.collection;
	const code = content.code;

	const [json, setJson] = useState({});

	let data = {
		query: {
			// code can be "code" field or "_id" field that is an ObjectId
			$or: [{ code: code }, { _id: code }, { _id: code }],
			active: { $in: [true, false, null] }
		},
		parseID: ["$or.[1]._id"]
	};

	if (client === "catalog") {
		data.catalog = "cockpit";
	} else {
		data.assistantID = client;
	}

	useEffect(() => {
		dispatch(
			FormAction.getItemsFromCollection(collection, data, res => {
				if (res.items.length > 0) {
					setJson(res.items[0]);
				}
			})
		);
	}, [client, collection, code]);

	return (
		<MDBox className="rightDrawerContainer">
			<MDBox className="rightDrawer" shadow="xl">
				<Card sx={{ p: 2, height: "100%" }}>
					<MDBox display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6">Document</MDTypography>
						<Tooltip placement="top" title="Fermer">
							<IconButton onClick={closePannel}>
								<Icon>close</Icon>
							</IconButton>
						</Tooltip>
					</MDBox>
					<Divider />
					<JSONTree data={json} theme={{ base00: "none" }} />
				</Card>
			</MDBox>
		</MDBox>
	);
};

export default RightPannel;

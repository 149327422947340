import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	insertDictionary: (accountID, data, onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/dictionary/${accountID}`,
					data,
					onSuccess,
					onFailure
				})
			);
		};
	},
	updateDictionary: (code, update, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/dictionary/${code}`,
					data: update,
					onSuccess,
					onFailure
				})
			);
		};
	},
	getSettings: (type, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/settings/${type}`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	newSetting: (category, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/settings/${category}`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	editSetting: (category, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/settings/${category}`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	deleteSetting: (category, query, onSuccess, catalog = null) => {
		let data = {
			query,
			catalog
		};
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/settings/${category}`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	reorderPage: (datas, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/settings/page/${datas.from}/${datas.to}`,
					onSuccess,
					data: {},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "rrr"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;

/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tooltip
} from "@mui/material";
import lod_ from "lodash";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import C from "../charts/steps/constants";
import IconAccordion from "../charts/steps/components/IconAccordion";
import DictionaryMenu from "../filters/DictionaryMenu";

export default function EditPageDialog({
	open,
	handleCloseDialog,
	handleSave,
	page,
	filtersList,
	dictionary
}) {
	const [name, setName] = useState(null);
	const [icon, setIcon] = useState(null);
	const [filterCode, setFilterCode] = useState(null);
	const [collection, setCollection] = useState(Object.keys(dictionary));

	const [anchorEl, setAnchorEl] = useState(null);
	const [routeDictLabel, setRouteDictLabel] = useState("");

	const [form, setForm] = useState({
		active: true,
		collectionDefault: null,
		pageLabel: null,
		routeDictionary: null
	});

	const disabledForType = type => {
		switch (type) {
			case "route":
				if (!name) {
					return true;
				}
				break;
			case "form":
				if (!form.collectionDefault || form.collectionDefault === "none") {
					return true;
				}
				break;
			case "title":
				if (!name) {
					return true;
				}
				break;
			case "divider":
				return false;
			default:
				return false;
		}

		return false;
	};

	const isDisabled = disabledForType(page.type);

	function close() {
		handleCloseDialog();
	}

	function sumbit() {
		let copy = page;
		if (name) {
			copy.name = name;
		}
		copy.icon = icon;
		copy.filter = filterCode;
		copy.form = form;
		delete copy.open;
		handleSave(copy);
		close(true);
	}

	useEffect(() => {
		setName(page.name);
		setIcon(page.icon);
		let realCode = filtersList?.find(filter => filter.name === page.filter);
		setFilterCode(realCode?.code ?? page.filter);
		setForm(page?.form?.active || false);
		setForm({
			active: page?.form?.active || false,
			collectionDefault: page?.form?.collectionDefault || null,
			pageLabel: page?.form?.pageLabel || null,
			routeDictionary: page?.form?.routeDictionary || ""
		});

		let route = page?.form?.routeDictionary || "";
		let itemLabel = lod_.get(dictionary, route)?.label?.fr;

		setRouteDictLabel(itemLabel);
	}, [page, filtersList]);

	function getFiltersDetails() {
		if (!filterCode) return "";
		let filter = filtersList.find(filter => filter.code === filterCode);
		if (!filter) {
			// When page used a deleted filter
			setFilterCode(null);
			return "";
		}
		let detail = filter.filters.map(filter => filter.fr).join(" / ");
		return detail;
	}

	const updateItem = item => {
		let route = item.replaceAll(".", ".items.");
		setForm({ ...form, routeDictionary: route });
		let itemLabel = lod_.get(dictionary, route)?.label?.fr;
		setAnchorEl(null);
		setRouteDictLabel(itemLabel);
	};

	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={() => close()}>
			<DialogTitle>{i18n.t("SETTINGS.PAGES.editPage")}</DialogTitle>
			<DialogContent>
				{page.type === "divider" && (
					<MDTypography variant="body2">{i18n.t("SETTINGS.PAGES.noParamsForRoute")}</MDTypography>
				)}
				{page.type !== "divider" && (
					<MDBox mt={1}>
						<MDInput
							className="dialogInput"
							label={i18n.t("SETTINGS.PAGES.name")}
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</MDBox>
				)}
				{page.type === "route" && (
					<>
						<MDBox mt={2}>
							<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.icon")}</MDTypography>
						</MDBox>
						<MDBox
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center"
							}}
						>
							{icon && <Icon fontSize="large">{icon}</Icon>}
							{!icon && (
								<MDTypography
									variant="h6"
									style={{
										fontStyle: "italic"
									}}
								>
									{i18n.t("SETTINGS.PAGES.noIcon")}
								</MDTypography>
							)}
							<div>
								<Tooltip placement="top" title={i18n.t("SETTINGS.PAGES.deleteIcon")}>
									<IconButton
										color="error"
										onClick={() => {
											setIcon(null);
										}}
									>
										<Icon fontSize="large">close</Icon>
									</IconButton>
								</Tooltip>
							</div>
						</MDBox>

						<IconAccordion
							title={i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
							open
							content={C.ICONS_LIST.map((icon, index) => {
								return (
									<IconButton
										key={index}
										onClick={() => {
											setIcon(icon);
										}}
									>
										<Icon fontSize="large">{icon}</Icon>
									</IconButton>
								);
							})}
						/>

						<MDBox mt={2}>
							<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.filter")}</MDTypography>
						</MDBox>

						<MDBox mt={1}>
							<FormControl fullWidth>
								<InputLabel id="select-type">{i18n.t("SETTINGS.PAGES.filter")}</InputLabel>
								<Select
									labelId="select-type"
									value={filterCode || "none"}
									label={i18n.t("SETTINGS.PAGES.filter")}
									onChange={e => {
										if (e.target.value === "none") setFilterCode(null);
										else setFilterCode(e.target.value);
									}}
								>
									<MenuItem value="none">{i18n.t("SETTINGS.PAGES.none")}</MenuItem>
									{filtersList.map(filter => {
										return <MenuItem value={filter.code}>{filter.name}</MenuItem>;
									})}
								</Select>
							</FormControl>
						</MDBox>

						<MDBox mt={1}>
							<MDTypography variant="body2">{getFiltersDetails()}</MDTypography>
						</MDBox>

						{page.form && (
							<>
								<MDBox mt={2}>
									<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.form")}</MDTypography>
								</MDBox>

								<MDBox mt={1}>
									<FormControl fullWidth>
										<InputLabel id="select-label">Collection</InputLabel>
										<Select
											labelId="select-collection-form"
											value={form.collectionDefault || "none"}
											label="Collection"
											onChange={e => {
												setForm({
													...form,
													collectionDefault: e.target.value
												});
											}}
										>
											<MenuItem value="none">Aucune</MenuItem>
											{collection &&
												collection.map(filter => {
													return <MenuItem value={filter}>{filter}</MenuItem>;
												})}
										</Select>
									</FormControl>
								</MDBox>

								<MDBox mt={2}>
									<MDInput
										value={form.pageLabel}
										className="dialogInput"
										label="Label de page"
										onChange={e =>
											setForm({
												...form,
												pageLabel: e.target.value
											})
										}
									/>
								</MDBox>

								<MDBox mt={2}>
									<MDBox display="flex" alignItems="center">
										<MDButton variant="gradient" color="info" onClick={e => setAnchorEl(e.target)}>
											{i18n.t("SETTINGS.add")}
										</MDButton>
										<DictionaryMenu
											levelLayer
											dictionary={dictionary}
											anchorEl={anchorEl}
											handleInsertText={updateItem}
											handleClose={() => setAnchorEl(null)}
										/>
										<MDBox display="flex" ml={1}>
											<MDTypography variant="body2">
												{routeDictLabel ?? "Aucune selection"}
											</MDTypography>
										</MDBox>
									</MDBox>
								</MDBox>
							</>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={() => close()}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					disabled={isDisabled && page.type !== "divider"}
					variant="contained"
					color="info"
					onClick={sumbit}
				>
					{i18n.t("SETTINGS.edit")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}

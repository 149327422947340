/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-brace-presence */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

// Custom styles for the Configurator
import ConfiguratorRoot from "components/Advanced/Configurator/ConfiguratorRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav, setDarkMode, setOpenConfigurator } from "context";

import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";

function Configurator() {
	const reactDispatch = useDispatch();
	const { assistants, profile } = useSelector(state => state);

	const [controller, dispatch] = useMaterialUIController();
	const { openConfigurator, miniSidenav, darkMode } = controller;

	const [menu, setMenu] = useState(null);
	const [menuWidth, setMenuWidth] = useState("auto");

	const openMenu = event => {
		setMenu(event.currentTarget);
		setMenuWidth(event.target.clientWidth);
	};
	const closeMenu = () => setMenu(null);
	const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

	// const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
	return (
		<>
			{openConfigurator && (
				<div
					style={{
						height: "100%",
						width: "100%",
						backgroundColor: "#0000004a",
						position: "fixed",
						top: 0,
						left: 0,
						zIndex: 1200
					}}
					onClick={handleCloseConfigurator}
				></div>
			)}

			<ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="baseline"
					pt={4}
					pb={0.5}
					px={3}
				>
					<MDBox>
						<MDTypography variant="h5">{i18n.t("CONFIGURATOR.title")}</MDTypography>
						<MDTypography variant="body2" color="text">
							{i18n.t("CONFIGURATOR.subTitle")}
						</MDTypography>
					</MDBox>

					<Icon
						sx={({ typography: { size }, palette: { dark, white } }) => ({
							fontSize: `${size.lg} !important`,
							color: darkMode ? white.main : dark.main,
							stroke: "currentColor",
							strokeWidth: "2px",
							cursor: "pointer",
							transform: "translateY(5px)"
						})}
						onClick={handleCloseConfigurator}
					>
						close
					</Icon>
				</MDBox>

				<Divider />

				<MDBox pt={0.5} pb={3} px={3}>
					<MDBox
						sx={{ mt: 2 }}
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						lineHeight={1}
					>
						<MDTypography variant="h6">Light / Dark</MDTypography>

						<Switch checked={darkMode} onChange={handleDarkMode} />
					</MDBox>
				</MDBox>
			</ConfiguratorRoot>
		</>
	);
}

export default Configurator;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	code: null
};

export const error = initialState;

const slice = createSlice({
	name: "error",
	initialState,
	reducers: {
		setError: (state, action) => {
			let { message, code, status } = action.payload;
			state.message = message ?? "Internal Error";
			state.status = status ?? 500;
			state.code = code ?? "internalError";
		}
	}
});

export const { setError } = slice.actions;

export default slice.reducer;

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-continue */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/**
 * Main application charts
 */
import "./style.css";
import lod_, { values } from "lodash";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import MDButton from "components/Basics/MDButton";
import FormActions from "redux-react/actions/formAction";
import DictionaryDataTable from "components/Custom/Tables/DictionaryDataTable";
import FormDictionaryDialog from "components/Custom/FormDictionary";
import { display } from "redux-react/reducers/snackBarReducer";
import {
	Autocomplete,
	Card,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	Tooltip
} from "@mui/material";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import ChangesTab from "./components/Changes";
import RightPannel from "./components/RightPannel";
import MappingTab from "./components/Mapping";

/**
 */
export default function DeploymentPage({ route }) {
	const dispatch = useDispatch();

	/*  */
	const [clients, setClients] = useState([]);
	const [clusters, setClusters] = useState([]);

	const [assistants, setAssistants] = useState([]);

	const [target, setTarget] = useState("client");
	const [account, setAccount] = useState("all");
	const [selectedAssistants, setSelectedAssistants] = useState([]);

	const [openPannel, setOpenPannel] = useState(false);
	const [pannelContent, setPannelContent] = useState(null);

	const [tab, setTab] = useState(0);

	const changePannelContent = content => {
		setPannelContent(content);
		setOpenPannel(true);
	};

	const closePannel = () => {
		setOpenPannel(false);
		setPannelContent(null);
	};

	const getTabContent = () => {
		switch (tab) {
			case 0:
				return <MappingTab />;
			case 2:
				return <ChangesTab changePannelContent={changePannelContent} />;
			default:
				return null;
		}
	};

	useEffect(() => {
		dispatch(
			FormActions.getItemsFromCollection(
				"accountConfig",
				{
					catalog: "legacy"
				},
				({ items }) => {
					setClients(items);
				}
			)
		);

		dispatch(
			FormActions.getItemsFromCollection(
				"clusterConfig",
				{
					catalog: "clusterConfig"
				},
				({ items }) => {
					setClusters(items);
				}
			)
		);
	}, []);

	return (
		<DashboardLayout style={{ height: "100%" }} display="flex">
			<DashboardNavbar
				filters={[
					<MDBox>
						<Tabs
							orientation="horizontal"
							value={tab}
							onChange={(e, v) => {
								setTab(v);
							}}
						>
							<Tab
								sx={{
									fontSize: "0.75rem"
								}}
								label="Mapping"
								icon={
									<Icon fontSize="small" sx={{ mt: -0.25 }}>
										move_up
									</Icon>
								}
							/>
							<Tab
								sx={{
									fontSize: "0.75rem"
								}}
								label="Deployment"
								icon={
									<Icon fontSize="small" sx={{ mt: -0.25 }}>
										settings
									</Icon>
								}
							/>
							<Tab
								sx={{
									fontSize: "0.75rem"
								}}
								label="Changes"
								icon={
									<Icon fontSize="small" sx={{ mt: -0.25 }}>
										compare_arrows
									</Icon>
								}
							/>
						</Tabs>
					</MDBox>
				]}
			/>
			<MDBox
				className="pageContentContainer"
				style={{
					display: openPannel ? "flex" : "block"
				}}
			>
				<MDBox flex="1">{getTabContent()}</MDBox>

				{openPannel && pannelContent && (
					<RightPannel closePannel={closePannel} content={pannelContent} />
				)}
			</MDBox>
		</DashboardLayout>
	);
}

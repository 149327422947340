import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	getDictionary: (onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/rule/dictionary`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	insertRule: (data, onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/rule/add`,
					data,
					onSuccess,
					onFailure
				})
			);
		};
	}
};

export default actions;

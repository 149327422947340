/* eslint-disable prefer-destructuring */
import { Card, Grid } from "@mui/material";
import {
	ComplexStatisticsCard,
	LoadingComplexStatisticsCard
} from "components/Advanced/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/Basics/MDBox";

const OneValueChart = ({ chart, data, ...rest }) => {
	if (!data || data.length === 0) {
		return (
			<Grid item {...rest}>
				<MDBox mb={1.5}>
					<LoadingComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						text={data.length === 0 ? "Aucune donnée" : "Chargement des données"}
					/>
				</MDBox>
			</Grid>
		);
	} else {
		/**
		 * Data must be an array with always one only element
		 */
		data = data[0];
		let percentage = {};

		if (data.total_ratio) {
			percentage = {
				color: parseFloat(data.total_ratio) > 0 ? "success" : "error",
				amount:
					parseFloat(data.total_ratio) > 0 ? `+ ${data.total_ratio} %` : `${data.total_ratio} %`,
				label: "par rapport à la période précédente"
			};
		}

		return (
			<Grid item {...rest}>
				<MDBox mb={1.5}>
					<ComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						count={`${data.total} ${chart.display.label ?? ""}`}
						percentage={percentage}
					/>
				</MDBox>
			</Grid>
		);
	}
};

export default OneValueChart;

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import "./style.css";
import lod_ from "lodash";
import { Icon, IconButton, InputAdornment, TableCell, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { useEffect, useState } from "react";
import { socket } from "redux-react/middleware/ws";
import { t } from "i18next";
import MDInput from "components/Basics/MDInput";
import MDButton from "components/Basics/MDButton";
import RowMapping from "./rowMapping";

const MappingTab = () => {
	const [dictionaries, setDictionaries] = useState({});

	const [mapping, setMapping] = useState({});

	const updateDictionaries = ({ success, result }) => {
		if (success) {
			setDictionaries(result.dictionaries);
		}
	};

	/**
	 * List all possible path from mapping object, like: "value1.path2.path3", "value1.path2.path4", ...
	 */
	function getAllPaths(obj, currentPath = "") {
		return lod_.flatMap(lod_.keys(obj), key => {
			const newPath = currentPath ? `${currentPath}.${key}` : key;
			if (lod_.isObject(obj[key]) && !lod_.isArray(obj[key])) {
				return getAllPaths(obj[key], newPath);
			}
			return newPath;
		});
	}

	const onChangePath = (oldPath, newPath, value) => {
		setMapping(prev => {
			const newMapping = lod_.cloneDeep(prev);
			lod_.set(newMapping, newPath, value);
			lod_.unset(newMapping, oldPath);
			return newMapping;
		});
	};
	const onChangeValue = (path, value) => {
		setMapping(prev => {
			const newMapping = lod_.cloneDeep(prev);
			lod_.set(newMapping, path, value);
			return newMapping;
		});
	};

	const removeEmptyObjects = obj => {
		if (lod_.isObject(obj) && !lod_.isArray(obj)) {
			const filtered = lod_.mapValues(obj, removeEmptyObjects);
			let res = lod_.omitBy(filtered, e => {
				return lod_.isObject(e) && lod_.isEmpty(e);
			});
			return res;
		}
		return obj;
	};

	const deleteRow = path => {
		setMapping(prev => {
			const newMapping = lod_.cloneDeep(prev);
			lod_.unset(newMapping, path);
			return removeEmptyObjects(newMapping);
		});
	};

	// all values in leafs must be replaced by {{{ json <originvalue> }}}
	const getHandlerbarMapping = () => {
		const mappingCopy = lod_.cloneDeep(mapping);
		const paths = getAllPaths(mappingCopy);

		let stringifiedMapping = JSON.stringify(lod_.cloneDeep(mappingCopy));

		for (let path of paths) {
			const value = lod_.get(mappingCopy, path);

			stringifiedMapping = stringifiedMapping.replace(`:"${value}"`, `:{{{ json ${value} }}} `);
		}

		return stringifiedMapping;
	};

	useEffect(() => {
		socket.emit("get_dictionaries", {});

		socket.on("get_dictionaries_result", updateDictionaries);

		return () => {
			socket.off("get_dictionaries_result", updateDictionaries);
		};
	}, []);

	return (
		<MDBox mt={2} bgColor="white" p={1} borderRadius="md" display="flex" flexDirection="column">
			<MDBox display="flex" flexDirection="column" alignItems="center" sx={{ mt: 1 }}>
				<MDInput
					disabled
					fullWidth
					value={getHandlerbarMapping()}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip placement="top" title={t("SETTINGS.DICTIONARY.copyToClipboard")}>
									<IconButton
										color="info"
										style={{ pointerEvents: "visible" }}
										onClick={() => {
											navigator.clipboard.writeText(getHandlerbarMapping());
										}}
									>
										<Icon>copy</Icon>
									</IconButton>
								</Tooltip>
							</InputAdornment>
						)
					}}
				/>
				<MDButton
					onClick={() => {
						setMapping(prev => {
							return {
								...prev,
								null: "null"
							};
						});
					}}
				>
					Ajouter
				</MDButton>
				{/*  */}
				{getAllPaths(mapping).map((path, index) => (
					<RowMapping
						key={index}
						dictionaries={dictionaries}
						path={path}
						value={lod_.get(mapping, path)}
						onChangePath={onChangePath}
						onChangeValue={onChangeValue}
						deleteRow={deleteRow}
					/>
				))}
			</MDBox>
		</MDBox>
	);
};

export default MappingTab;

import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { selectAllSortedMenu } from "redux-react/reducers/profileReducer";

const FormDictionary = ({ submit }) => {
	const [name, setName] = useState("");
	const [code, setCode] = useState("");
	const [showWarning, setShowWarning] = useState(false);

	useEffect(() => {
		if (code.trim() !== "" && name.trim() !== "") {
			submit({
				valid: true,
				data: {
					name,
					code,
					pages: ["/dictionnaires"]
				}
			});
		} else {
			submit({
				valid: false
			});
		}
	}, [name, code]);

	const checkCodeItem = text => {
		let regex = /^[A-Za-z]+$/;
		if (regex.test(text)) {
			setCode(text);
		} else if (text === "") {
			setCode(text);
		} else {
			setShowWarning(true);
			setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}
	};

	return (
		<MDBox>
			<MDTypography variant="h6">{i18n.t("SETTINGS.DICTIONARY.newDictionary")}</MDTypography>
			<MDBox display="flex" flexDirection="column" mt={1}>
				<MDBox>
					<MDInput
						className="dialogInput"
						value={name}
						onChange={e => setName(e.target.value)}
						label={
							<span>
								{i18n.t("SETTINGS.DICTIONARY.dictionaryName")}
								<span className="mandatoryField">*</span>
							</span>
						}
					/>
				</MDBox>
				<MDBox mt={1}>
					<Tooltip
						open={showWarning}
						title={i18n.t("SETTINGS.RULES.regexCode")}
						placement="right"
						disableFocusListener
						disableHoverListener
						disableTouchListener
					>
						<MDInput
							className="dialogInput"
							value={code}
							onChange={e => checkCodeItem(e.target.value)}
							label={
								<span>
									{i18n.t("SETTINGS.DICTIONARY.dictionaryCode")}
									<span className="mandatoryField">*</span>
								</span>
							}
						/>
					</Tooltip>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default FormDictionary;

/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import { Divider, Icon } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import ProfileActions from "redux-react/actions/profileActions";
import SettingsActions from "redux-react/actions/settingsActions";

import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import ConfirmDialog from "components/Custom/Dialogs/ConformDialog";
import { display } from "redux-react/reducers/snackBarReducer";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import lod_ from "lodash";
import AddFilterDialog from "./AddFilterDialog";
import EditFilterDialog from "./EditFilterDialog";

export function createCode(assistantID) {
	const alpha = "ABCEHLSTUX";
	let mID = assistantID[0] + assistantID[2] + assistantID[assistantID.length - 1] + "_";
	let currentMilli = Date.now();
	let milli = "" + currentMilli;
	mID += milli.substring(2, 8) + "_";
	milli = milli.substring(8);
	mID += alpha[milli[0]] + milli[1] + milli[2] + alpha[milli[3]] + alpha[milli[4]];
	return mID;
}

export default function SettingsFilters({ route }) {
	const { user } = useSelector(state => state);
	const dispatch = useDispatch();

	const [paginedList, setPaginedList] = useState(null);

	const [addNewFilter, setAddNewFilter] = useState(false);
	const [editFilter, setEditFilter] = useState({
		open: false
	});
	// Confirm dialog state
	const [confirmDialog, setConformDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});

	function loadPaginedList() {
		const onSuccess = res => {
			setPaginedList(res.paginedList[0]);
		};

		dispatch(ChartsActions.getPaginedList(route, onSuccess));
	}

	useEffect(() => {
		loadPaginedList();
	}, []);

	const handleSaveNewFilter = filter => {
		filter.code = createCode(user.accountID);
		filter.active = true;
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.FILTERS.SUCCESS.add"),
					type: "success"
				})
			);
			loadPaginedList();
			dispatch(ProfileActions.updateMenu(user.accountID));
		};
		dispatch(SettingsActions.newSetting("filters", filter, onSuccess));
	};

	const handleEditFilter = filter => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.FILTERS.SUCCESS.edit"),
					type: "success"
				})
			);
			loadPaginedList();
		};
		dispatch(
			SettingsActions.editSetting(
				"filters",
				{ code: filter.code, name: filter.name, filters: filter.filters, active: filter.active },
				onSuccess
			)
		);
	};

	const actionDeleteHandle = filter => {
		const handleConfirmDialog = () => {
			setConformDialog({
				open: false
			});
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.FILTERS.SUCCESS.delete"),
						type: "success"
					})
				);
				loadPaginedList();
				dispatch(ProfileActions.updateMenu(user.accountID));
			};
			dispatch(SettingsActions.deleteSetting("filters", { code: filter.code }, onSuccess));
		};

		setConformDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} ${i18n.t("SETTINGS.FILTERS.theFilter")} ${filter.name}`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} ${i18n.t("SETTINGS.FILTERS.theFilter")} ${
				filter.name
			} ?`,
			handleConfirm: handleConfirmDialog
		});
	};

	const actionEditHandle = filter => {
		setEditFilter({
			open: true,
			...filter
		});
	};

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox display="flex">
						<MDButton
							style={{ height: "100%", marginRight: "0.75rem" }}
							variant="contained"
							color="info"
							onClick={() => setAddNewFilter(true)}
						>
							<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.FILTERS.add")}
						</MDButton>
					</MDBox>
				]}
			/>
			<Divider />
			{paginedList && (
				<DefaultDataTable
					list={paginedList}
					dictionary={paginedList.dictionary}
					canSearch
					table={paginedList.data}
					display={paginedList.request.attributesDisplay}
					actionEditHandle={actionEditHandle}
					actionDeleteHandle={actionDeleteHandle}
				/>
			)}
			{paginedList && (
				<AddFilterDialog
					open={addNewFilter}
					handleCloseDialog={() => setAddNewFilter(false)}
					handleSave={handleSaveNewFilter}
					dictionary={paginedList.dictionary}
				/>
			)}
			{paginedList && (
				<EditFilterDialog
					open={editFilter.open}
					handleCloseDialog={() => setEditFilter({ open: false })}
					handleSave={handleEditFilter}
					dictionary={paginedList.dictionary}
					filter={editFilter}
				/>
			)}
			<ConfirmDialog
				open={confirmDialog.open}
				title={confirmDialog.title}
				content={confirmDialog.content}
				handleClose={() => {
					setConformDialog({
						open: false
					});
				}}
				handleConfirm={confirmDialog.handleConfirm}
			/>
		</DashboardLayout>
	);
}

/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Labels settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Box,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Switch
} from "@mui/material";
import { useEffect, useState } from "react";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import MDTypography from "components/Basics/MDTypography";
import OneValueChart from "pages/charts/oneValueChart";
import ProportionChart from "pages/charts/proportionChart";
import CrossTable from "pages/charts/crossTable";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import ChartsActions from "redux-react/actions/chartsActions";
import notFoundImage from "assets/images/error.svg";
import IconAccordion from "./steps/components/IconAccordion";
import C from "./steps/constants";

export default function EditChartDialog({ open, handleCloseDialog, handleSave, originalChart }) {
	const [chart, setChart] = useState({});

	const isEqual = lod_.isEqual(chart, originalChart);
	const isDisabled = !chart?.display?.title || chart?.display?.pages?.length === 0;

	const [error, setError] = useState(false);
	const dispatch = useDispatch();
	const pages = useSelector(state =>
		state.profile.selectedAssistant.menu
			.filter(item => item.type === "route" && !item.system)
			.sort((a, b) => a.rank - b.rank)
	);

	const [previousRequest, setPreviousRequest] = useState({});

	const [previewChart, setPreviewChart] = useState(false);
	const [selectedPages, setSelectedPages] = useState([]);

	const [switchLabelController, setSwitchLabelController] = useState(false);

	function close() {
		handleCloseDialog();
	}

	function sumbit() {
		handleSave(chart);
		close();
	}

	const handleChange = (path, value) => {
		let copyChart = lod_.cloneDeep(chart);
		copyChart = lod_.set(copyChart, path, value);

		setChart(copyChart);
	};

	const handleSwitchLabel = () => {
		setSwitchLabelController(!switchLabelController);
		handleChange("display.datalabels", !switchLabelController);
	};

	function getCorrectGraph(type) {
		try {
			switch (type) {
				case "oneValue":
					return <OneValueChart chart={chart} data={previewChart.data} />;
				case "proportion":
				case "oneDimension":
					return <ProportionChart chart={chart} data={previewChart.data} />;
				case "crossTable":
				case "timeSeries":
				case "multiCollection":
					return <CrossTable chart={chart} data={previewChart.data} />;
				case "paginedList":
					return (
						<DefaultDataTable
							list={chart}
							pagination={previewChart.pagination}
							canSearch
							table={previewChart.data}
							display={chart.request.attributesDisplay}
						/>
					);
				default:
					return null;
			}
		} catch (e) {
			return null;
		}
	}

	const handleChangeSelect = event => {
		let { value } = event.target;
		setSelectedPages(typeof value === "string" ? value.split(",") : value);
	};

	function loadChart(chartToLoad = chart) {
		const onSuccess = res => {
			if (res.charts.length === 0) {
				setError(true);
			} else {
				setPreviewChart(res.charts[0]);
			}
		};
		dispatch(ChartsActions.buildRawCharts([chartToLoad], [], [], onSuccess));
	}

	useEffect(() => {
		if (!lod_.isEqual(previousRequest, chart.request)) {
			setPreviousRequest(chart.request);
			loadChart();
		}
	}, [chart.request]);

	useEffect(() => {
		handleChange(
			"display.pages",
			selectedPages.map(page => page.replace("/", ""))
		);
	}, [selectedPages]);

	useEffect(() => {
		setChart({});
		setPreviewChart({});
		setError(false);
		if (originalChart) {
			setChart(originalChart);
			loadChart(originalChart);
			setSwitchLabelController(Boolean(originalChart?.display?.datalabels));
			setPreviousRequest(originalChart.request);
			setSelectedPages(originalChart.display.pages.map(page => `/${page}`));
		}
	}, [open === true]);

	return (
		<Dialog
			fullWidth
			maxWidth="xxxl"
			PaperProps={{
				sx: {
					height: "95%"
				}
			}}
			open={open}
			onClose={() => close()}
		>
			<DialogTitle>{i18n.t("SETTINGS.CHARTS.edit")}</DialogTitle>
			<DialogContent>
				{!lod_.isEmpty(chart) && !lod_.isEmpty(previewChart) && (
					<MDBox display="flex" className="fullHeight">
						{/* Left tab */}
						<MDBox flex="3" className="displayLeftTab">
							<MDTypography variant="h3">{i18n.t("SETTINGS.CHARTS.NEW.chartPreview")}</MDTypography>

							<MDBox className="dialogContentChartEditor fullHeight" p={3} borderRadius="md">
								<MDBox mt={4}>{previewChart && getCorrectGraph(chart.type)}</MDBox>
								{error && (
									<MDBox
										mt={2}
										display="flex"
										justifyContent="center"
										flexDirection="column"
										alignItems="center"
									>
										<MDBox
											mt={-1}
											component="img"
											src={notFoundImage}
											alt="not found"
											width="60%"
										></MDBox>
										<MDTypography variant="caption" color="error" mt={-1} fontSize="medium">
											{i18n.t("SETTINGS.CHARTS.NEW.cannotPreview")}
										</MDTypography>
									</MDBox>
								)}
							</MDBox>
						</MDBox>
						{/* Right Tab */}
						<MDBox flex="2" ml={1} className="displayRightTab">
							<MDTypography variant="h3">{i18n.t("SETTINGS.CHARTS.NEW.display")}</MDTypography>

							<MDBox mt={2}>
								{/* Titre */}
								<MDBox mt={1}>
									<MDInput
										className="dialogInput"
										label={
											<span>
												{i18n.t("SETTINGS.CHARTS.NEW.chartTitle")}
												<span className="mandatoryField">*</span>
											</span>
										}
										value={chart.display.title || ""}
										onChange={e => handleChange("display.title", e.target.value)}
									/>
								</MDBox>
								{/* Description */}
								{chart.type !== "oneValue" && (
									<MDBox mt={2}>
										<MDInput
											className="dialogInput"
											label={i18n.t("SETTINGS.CHARTS.NEW.chartDescription")}
											value={chart.display.description || ""}
											onChange={e => handleChange("display.description", e.target.value)}
										/>
									</MDBox>
								)}
								{/* Icon */}
								<MDBox mt={1}>
									<MDTypography variant="overline">
										{i18n.t("SETTINGS.CHARTS.NEW.icon")}
									</MDTypography>
								</MDBox>
								<MDBox display="flex" alignItems="flex-start " flexDirection="column">
									<MDBox mt={1}>
										{/* Color choice */}
										<IconAccordion
											title={i18n.t("SETTINGS.CHARTS.NEW.iconAvailableColors")}
											content={
												<MDBox display="flex" flexWrap="wrap">
													{C.ICONS_COLORS.map((color, index) => {
														return (
															<MDBox
																mr={1}
																bgColor={color}
																variant="gradient"
																coloredShadow={color}
																borderRadius="xl"
																className="colorPickerContainer"
																onClick={() => {
																	handleChange("display.icon.color", color);
																}}
															>
																<Icon fontSize="small">{chart.display.icon.component}</Icon>
															</MDBox>
														);
													})}
												</MDBox>
											}
										/>
										{/* Icon choice */}
										<IconAccordion
											title={i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
											content={C.ICONS_LIST.map((icon, index) => {
												return (
													<IconButton
														key={index}
														onClick={() => {
															let newIcon = {
																component: icon,
																color: chart.display.icon?.color || "info"
															};
															handleChange("display.icon", newIcon);
														}}
													>
														<Icon fontSize="large">{icon}</Icon>
													</IconButton>
												);
											})}
										/>
									</MDBox>
								</MDBox>
								{/* Pages */}
								<MDBox mt={3}>
									<FormControl fullWidth>
										<InputLabel id="select-pages-label">
											<span>
												{i18n.t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
												<span className="mandatoryField">*</span>
											</span>
										</InputLabel>
										<Select
											labelId="select-pages-label"
											id="select-pages"
											multiple
											value={selectedPages}
											onChange={handleChangeSelect}
											input={
												<OutlinedInput
													id="select-pages-chip"
													label={
														<span>
															{i18n.t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
															<span className="mandatoryField">*</span>
														</span>
													}
												/>
											}
											renderValue={selected => (
												<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
													{selected.map((page, index) => {
														let goodPage = pages.find(p => p.route === page);
														return <Chip key={index} label={goodPage.name} />;
													})}
												</Box>
											)}
										>
											{pages.map((page, index) => (
												<MenuItem key={index} value={page.route}>
													{page.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</MDBox>
								{/* ################################################ */}
								{/*	PART FOR : ONEVALUE / ONEDIMENSION / PROPORTION  */}
								{/* ################################################ */}
								{["oneValue", "oneDimension", "proportion"].includes(chart.type) && (
									<>
										{/* Limit */}
										{chart.type !== "oneValue" && (
											<MDBox mt={2}>
												<MDInput
													value={chart.request.limit}
													type="number"
													className="dialogInput"
													label={i18n.t("SETTINGS.CHARTS.NEW.displayLimit")}
													InputProps={{ inputProps: { min: 1 } }}
													onChange={e => {
														if (e.target.value < 1) e.target.value = null;
														handleChange("request.limit", e.target.value);
													}}
												/>
											</MDBox>
										)}
										{/* Labels */}
										<MDBox mt={2}>
											<MDInput
												value={chart.display.label || ""}
												className="dialogInput"
												label={i18n.t("SETTINGS.CHARTS.NEW.displayLabel")}
												onChange={e => handleChange("display.label", e.target.value)}
											/>
										</MDBox>
										{/* Etiquettes */}
										{chart.type !== "oneValue" && (
											<MDBox
												mt={2}
												display="flex"
												justifyContent="space-between"
												alignItems="center"
												className="customSwitchContainer"
												onClick={() => handleSwitchLabel()}
											>
												<span>{i18n.t("SETTINGS.CHARTS.NEW.displayDatalabels")}</span>
												<MDBox>
													<Switch checked={switchLabelController} />
												</MDBox>
											</MDBox>
										)}
									</>
								)}
							</MDBox>
						</MDBox>
					</MDBox>
				)}
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={() => close()}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					disabled={isDisabled || isEqual}
					variant="contained"
					color="dark"
					onClick={sumbit}
				>
					{i18n.t("SETTINGS.edit")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}

/* eslint-disable no-underscore-dangle */

/**
 * Main application forms
 */
import "./style.css";
import lod_, { values } from "lodash";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon } from "@mui/material";
import i18n from "i18n";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";

import FormDictionaryDialog from "components/Custom/FormDictionary";
import { getLocalStorageBackValues, parseFilters } from "components/Custom/Filters/filters";

const ButtonNewLabel = ({ handleClick, namePage }) => {
	return (
		<MDButton
			style={{ height: "100%", marginRight: "0.75rem" }}
			variant="contained"
			color="info"
			onClick={handleClick}
		>
			<Icon>add</Icon>&nbsp;{i18n.t("FORMS.LABELS.add") + namePage}
		</MDButton>
	);
};

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function FormsPage({ route }) {
	const dispatch = useDispatch();
	const [addNewLabel, setAddNewLabel] = useState(false);
	const [valuesForm, setValuesForm] = useState({});
	const [formBuild, setFormBuild] = useState({});
	const [contextDictionary, setContextDictionary] = useState({});
	const [PI, setPI] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [useLegacy, setUseLegacy] = useState(false);
	const [dbName, setDbName] = useState(null);
	const { profile, filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Raw charts from collection "analytics" in DB
	const [rawCharts, setRawCharts] = useState({});
	// Raw charts builded from the back
	const [chartsData, setChartsData] = useState({});
	// Default assistant filters
	const [pageFilters, setPageFilters] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [selectedTarget, setSelectedTarget] = useState("");
	const [routeDict, setRouteDict] = useState("");
	const [selectedId, setSelectedId] = useState(null);
	const [reloadTable, setReloadTable] = useState(false);
	const [listAPIs, setlistAPIs] = useState([]);

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData() {
		return chartsData[route.route] ?? [];
	}
	/**
	 * FIRST STEP : Get raw charts from DB
	 */
	function getRawChartsFromDatabase() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts({ ...rawCharts, [route.route]: res.charts });
		};
		dispatch(ChartsActions.getCharts(route, onSuccess));
	}
	/**
	 * SECOND STEP : Build charts with the raw charts on the back
	 */
	function buildRawCharts() {
		// Success handler : set charts data
		const onSuccess = res => {
			setChartsData({ ...chartsData, [route.route]: res.charts });
			setChartsLoading(false);
			// get the raw chart outside of the array format where the key corresponds to route.route
			const chart = rawCharts[route.route][0];
			let operation;
			if (lod_.isNil(chart.operation) || chart.operation === false) {
				operation = false;
			} else {
				operation = true;
			}
			setUseLegacy(operation);
			if (chart.request.dbName) {
				setDbName(chart.request.dbName);
			}
		};
		// Build charts
		if (!rawCharts[route.route]) return;
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		dispatch(
			ChartsActions.buildRawCharts(rawCharts[route.route], filters, mandatoryFilters, onSuccess)
		);
	}
	/**
	 * FIRST STEP : Get raw charts :
	 * - On mount / when page change
	 * - On selected assistant change
	 */
	useEffect(() => {
		// if (!chartsData[page]) {
		setChartsLoading(true);
		getRawChartsFromDatabase();
		// }
	}, [profile.selectedAssistant.assistantID, route]);
	/**
	 * SECOND STEP : Build charts :
	 * - On filters change
	 * - On rawCharts change
	 */
	useEffect(() => {
		buildRawCharts();
	}, [filters, rawCharts]);
	/**
	 * Load filters on mount
	 */
	useEffect(() => {
		// setAssistantFilters(["date"]);
	}, []);

	const actionEditHandle = (items, target) => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(false);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(false);
			setlistAPIs(res.APIs);
			setSelectedId(items._id);
			setSelectedTarget(target);
			setRouteDict(route.form.routeDictionary);
		};
		dispatch(
			FormActions.getItemByID(
				items._id,
				target,
				route.form.routeDictionary,
				onSuccess,
				useLegacy,
				dbName,
				false,
				route.form.catalog
			)
		);
	};

	const actionEditEmptyHandle = () => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(true);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(true);
			setlistAPIs(res.APIs);

			setSelectedTarget(route.form.collectionDefault);
			setRouteDict(route.form.routeDictionary);
		};

		dispatch(
			FormActions.getItemEmpty(route.form.collectionDefault, route.form.routeDictionary, onSuccess)
		);
	};

	const actionDeleteHandle = (item, target) => {
		setConfirmDelete(true);
		setSelectedItem(item);
		setSelectedTarget(target);
	};

	const closeDeleteHandle = () => {
		setConfirmDelete(false);
	};

	const deleteItemIndex = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setConfirmDelete(false);
		};
		if (selectedItem._id) {
			dispatch(
				FormActions.deleteItem(selectedItem._id, selectedTarget, onSuccess, useLegacy, dbName)
			);
		} else {
			setConfirmDelete(false);
			dispatch(
				display({
					message: i18n.t("FORMS.deleteError"),
					type: "error"
				})
			);
		}
	};

	const actionSaveOrCreateItem = (values, unique, callback) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setAddNewLabel(false);
			callback(true);
		};
		let data = { values, target: selectedTarget, unique, useLegacy, dbName };
		if (empty) {
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			dispatch(
				FormActions.updateItem(selectedId, data, onSuccess, err => {
					callback(false);
				})
			);
		}
	};

	/**
	 * Charts loader
	 */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/**
	 * Main component
	 */
		return (
			<DashboardLayout>
				<MDBox mb={3}>
					<DashboardNavbar
						filters={[
							<MDBox display="flex" flexDirection="row" alignItems="center">
								<ButtonNewLabel
									handleClick={actionEditEmptyHandle}
									namePage={route?.form?.pageLabel ?? route.name}
								/>
								{pageFilters && (
									<MDBox display="flex" alignItems="center">
										{parseFilters(route.route, pageFilters, filters, dispatch)}
									</MDBox>
								)}
							</MDBox>
						]}
					/>
				</MDBox>
				{addNewLabel && !lod_.isEmpty(valuesForm) && (
					<FormDictionaryDialog
						open={addNewLabel}
						route={route}
						handleCloseDialog={() => setAddNewLabel(false)}
						handleSave={(values, unique, callback) =>
							actionSaveOrCreateItem(values, unique, callback)
						}
						valuesDictionary={valuesForm}
						formBuildDictionary={formBuild}
						contextDictionary={contextDictionary}
						PIaccess={PI}
						isEmpty={empty}
						target={selectedTarget}
						selectedId={selectedId}
						routeDict={routeDict}
						listAPIs={listAPIs}
						useLegacy={useLegacy}
						dbName={dbName}
					/>
				)}
				{confirmDelete && (
					<Dialog open={confirmDelete} onClose={closeDeleteHandle}>
						<DialogTitle>{i18n.t("FORMS.LABELS.delete")}</DialogTitle>
						<DialogContent>{i18n.t("FORMS.LABELS.confirmDelete")}</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={closeDeleteHandle} variant="outlined" color="dark">
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={deleteItemIndex} color="dark" variant="contained" autoFocus>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}
				{/*
				 * Pagined table
				 */}
				{getChartsData()
					.filter(chart => chart.type === "paginedList")
					.map((chart, index) => {
						return (
							<DefaultDataTable
								form={route.form}
								dictionary={chart.dictionary}
								canSearch
								table={chart.data}
								display={chart.request.attributesDisplay}
								pagination={chart.pagination}
								list={chart}
								reloadTable={reloadTable}
								filters={getLocalStorageBackValues(route.route, filters)}
								actionEditHandle={items => actionEditHandle(items, chart.request.collection)}
								actionDeleteHandle={items => actionDeleteHandle(items, chart.request.collection)}
								collection={chart.request.collection}
							/>
						);
					})}
			</DashboardLayout>
		);
}

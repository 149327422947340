import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	getItemByID: (
		_id,
		collection,
		route,
		onSuccess,
		operation = false,
		dbName = null,
		accessPI = false,
		catalog = null
	) => {
		let data = {
			operation,
			_id,
			accessPI,
			collection,
			route,
			dbName,
			catalog
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getItemEmpty: (target, route, onSuccess) => {
		let data = {
			route
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/empty/${target}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	addItemEmpty: (data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/empty`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},
	updateItem: (_id, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/form/update/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},
	deleteItem: (_id, collection, onSuccess, useLegacy = false, dbName = null, catalog = null) => {
		let data = { target: collection, useLegacy, catalog };
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/delete/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error?.message ?? "none"));
					}
				})
			);
		};
	},
	// Get items from collection
	getItemsFromCollection: (collection, data, onSuccess, cluster = {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/collection/${collection}`,
					data: { cluster, ...data },
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error?.message ?? "none"));
					}
				})
			);
		};
	},
	getRegexLittleForm: (typeRegex = "email", onSuccess) => {
		let data = {
			typeRegex
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/litleForm/regex`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getListApis: onSuccess => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/litleForm/listApis`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	}
};

export default actions;
